import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import { Client, NormalizedSupplierExt, StudySuppliersMatchQueryArgs } from '@curvo/apollo'
import axios from 'axios'
import { isNil, omitBy } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { bufferCount, Observable, map as rxMap } from 'rxjs'
import { jsonToTable, parseJSON, splitStream } from '../../../../components/streamLoader'
import Cognito from '../../../../config/Cognito'

export function useLoadNormalizedSuppliersWithPipe(
  gridRef: React.RefObject<AgGridReact>,
  queryArgs: StudySuppliersMatchQueryArgs,
) {
  const [normalizedSuppliers, setNormalizedSuppliers] = useState<NormalizedSupplierExt[]>([])
  const [total, setTotal] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const memoQueryArgs = useRef<StudySuppliersMatchQueryArgs>()

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.setRowData([])
      gridRef.current.api.showLoadingOverlay()
    }

    if (JSON.stringify(memoQueryArgs.current) !== JSON.stringify(queryArgs)) {
      memoQueryArgs.current = queryArgs
    }

    setTotal(-1)
    setNormalizedSuppliers([])
    setLoading(false)
  }, [queryArgs, gridRef])

  useEffect(() => {
    const getToken = async () => {
      const session = await Cognito.getSession()
      return session.getAccessToken().getJwtToken()
    }

    const loadNormalizedSuppliers = () => {
      doFetchNormalizedSuppliersPipe(memoQueryArgs.current!)
    }

    const doGetNormalizedSuppliersCount = async (args: StudySuppliersMatchQueryArgs) => {
      setLoading(true)
      const baseUrl = Client.getUrl()
      try {
        const token = await getToken()
        const result = await axios.get(`${window.location.protocol}//${baseUrl}/study-normalized-suppliers/count`, {
          params: omitBy(
            {
              from: args.studyDateRange?.from,
              to: args.studyDateRange?.to,
              id: args.id,
              search: args.search,
            },
            isNil,
          ),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (args === memoQueryArgs.current) {
          setTotal(result.data.count)
          if (result.data.count === 0) {
            gridRef.current?.api?.showNoRowsOverlay()
          }
        }
        return result.data.count
      } catch (e) {
        setError(e)
      }
    }

    const doFetchNormalizedSuppliersPipe = async (args: StudySuppliersMatchQueryArgs) => {
      const baseUrl = Client.getUrl()
      try {
        const token = await getToken()
        new Observable<NormalizedSupplierExt>(observer => {
          fetch(
            `${window.location.protocol}//${baseUrl}/study-normalized-suppliers?${new URLSearchParams(
              omitBy(
                {
                  from: args.studyDateRange?.from,
                  to: args.studyDateRange?.to,
                  id: args.id,
                  search: args.search,
                },
                isNil,
              ) as any,
            ).toString()}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ).then(response => {
            response.body
              ?.pipeThrough(new TextDecoderStream())
              .pipeThrough(splitStream())
              .pipeThrough(parseJSON())
              .pipeTo(jsonToTable(observer))
          })
        })
          .pipe(
            bufferCount<NormalizedSupplierExt>(512),
            rxMap(chunks => {
              setNormalizedSuppliers(old => [...old, ...chunks])

              gridRef.current?.api?.applyTransaction({
                add: chunks,
              })
            }),
          )
          .subscribe({
            complete: () => {
              gridRef.current?.api?.hideOverlay()
            },
          })
      } catch (e) {
        setError(e)
      }
    }

    // if we have cached data (size > 0, and have data === total, and cached queryArgs === current query args)
    if (total === -1 && !loading) {
      doGetNormalizedSuppliersCount(memoQueryArgs.current!)
      loadNormalizedSuppliers()
    }
  }, [total, normalizedSuppliers.length, gridRef, loading])

  return {
    progress:
      total === -1
        ? 5 * (normalizedSuppliers.length / 512)
        : Math.round(normalizedSuppliers && total ? ((normalizedSuppliers.length * 1.0) / total) * 100 : 0),
    error,
    loading: total === -1 || normalizedSuppliers.length < total,
    normalizedSuppliers,
  }
}
