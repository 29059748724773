import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-material.css'
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
import {
  AllModules,
  ColumnState,
  DragStoppedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
} from '@ag-grid-enterprise/all-modules'
import {
  MeWithoutPaymentContext,
  StudiesQueryArgs,
  Study,
  StudyArtifacts,
  StudyFileTypeEnum,
  StudyStatusEnum,
  User,
  archiveStudyMutation,
  cancelStudyMutation,
  completeStudyMutation,
  createNewConflictStudy,
  deleteStudyMutation,
  genStudyArtifactsUrlMutation,
  importRawTransactionMutation,
  linkStudyMutation,
  recoverStudyMutation,
  retryLinkingStudyMutation,
  retryStudyMutation,
  rollbackStudyMutation,
  updateStudy,
  useStudiesData,
  useSubscriptionStudiesUpdated,
} from '@curvo/apollo'
import { Button, DatePicker, Icon, Input, Modal, Popconfirm, Progress, Select, message } from 'antd'
import * as H from 'history'
import { pick } from 'lodash'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { SearchWrapper } from '../Update/common'
import { AgGridDatePickerEditor } from './components/AgGridDatePickerEditor'
import { ImportRawTransactionDrawer } from './components/ImportRawTransactionDrawer'
import { CurvoAdminUserSelectAgWrapper } from './components/UserSelectAgWrapper'

const STUDY_GRID = 'STUDY_GRID'

const DEFAULT_STATUS_IN_ARRAY = [
  StudyStatusEnum.Grooming,
  StudyStatusEnum.Grooming2,
  StudyStatusEnum.New,
  StudyStatusEnum.CaseReview,
]

export const StudyTable: React.FC = () => {
  const navigate = useNavigate()

  const [firstTimeGridSizeOptimized, setFirstTimeGridSizeOptimized] = useState(false)
  const [isOpenImportPanel, setIsOpenImportPanel] = useState(false)
  const [loadingStates, setLoadingStates] = useState<{ importRawTransaction: boolean; createConflictStudy: boolean }>({
    importRawTransaction: false,
    createConflictStudy: false,
  })
  const gridRef = useRef<AgGridReact & { api: GridApi }>(null)
  const defaultDateFilter = useMemo(() => [moment().subtract(6, 'month'), moment()], [])
  const [studiesQueryArgs, setStudiesQueryArgs] = useState<StudiesQueryArgs>({
    first: 100,
    statusIn: DEFAULT_STATUS_IN_ARRAY,
    importedFrom: defaultDateFilter[0],
    importedTo: defaultDateFilter[1],
    fileType: [StudyFileTypeEnum.Other, StudyFileTypeEnum.Epic, StudyFileTypeEnum.Contract],
  })
  const [selectedStatus, setSelectedStatus] = useState<StudyStatusEnum[] | undefined>(DEFAULT_STATUS_IN_ARRAY)

  const [isOnlyMe, setIsOnlyMe] = useState(false)

  useEffect(() => {
    function handleOnResize() {
      if (gridRef.current) {
        gridRef.current.api.sizeColumnsToFit()
      }
    }

    window.addEventListener('resize', handleOnResize)
    return function cleanUp() {
      window.removeEventListener('resize', handleOnResize)
    }
  })

  const updatedStudiesResult = useSubscriptionStudiesUpdated()
  if (updatedStudiesResult.error) {
    message.error(updatedStudiesResult.error)
  }

  useEffect(() => {
    if (updatedStudiesResult.data && gridRef.current) {
      const rowNode = gridRef.current.api.getRowNode(updatedStudiesResult.data.studiesUpdated.id.toString())
      if (updatedStudiesResult.data.studiesUpdated.status === StudyStatusEnum.Archived) {
        gridRef.current.api.applyTransaction({
          remove: [updatedStudiesResult.data.studiesUpdated],
        })
      } else {
        if (rowNode) {
          rowNode.setData(updatedStudiesResult.data.studiesUpdated)
        }
      }
    }
  }, [updatedStudiesResult.data])

  const { error, loading, ...studiesDataResult } = useStudiesData({
    fetchPolicy: 'no-cache',
    variables: studiesQueryArgs,
  })

  if (error) {
    message.error(error.message)
  }

  useEffect(() => {
    const data = studiesDataResult.data
    const studies = (data && data.studies) || []
    if (gridRef.current) {
      gridRef.current.api.setRowData(studies)
      gridRef.current.api.hideOverlay()
      if (loading) {
        gridRef.current.api.showLoadingOverlay()
      }
    }
  }, [studiesDataResult.data, loading])

  useEffect(() => {
    if (!firstTimeGridSizeOptimized && (studiesDataResult.data?.studies?.length || 0) > 0) {
      setFirstTimeGridSizeOptimized(true)
      if (gridRef.current) {
        gridRef.current.api.sizeColumnsToFit()
      }
    }
  }, [studiesDataResult.data, firstTimeGridSizeOptimized])

  const columns: AgGridColumnProps[] = [
    {
      headerName: 'ID',
      colId: 'id',
      filter: 'agSetColumnFilter',
      width: 150,
      valueGetter: ({ data }) => data && `${data.id}.${data.status}`,
      cellRendererFramework: StudyIdAgRenderer,
      cellRendererParams: {
        history: history,
      },
      comparator: (_valueA, _valueB, nodeA, nodeB) =>
        (nodeA.data ? nodeA.data.id : 0) - (nodeB.data ? nodeB.data.id : 0),
    },
    {
      headerName: 'Customer',
      valueGetter: ({ data }) => `${data.facilityId} | ${data.facilityName}`,
      filter: 'agSetColumnFilter',
      colId: 'customer',
    },
    { headerName: 'File Type', field: 'fileType', colId: 'fileType', filter: 'agSetColumnFilter', width: 140 },
    {
      headerName: 'From',
      width: 140,
      field: 'minDatePurchase',
      colId: 'minDatePurchase',
      filter: 'agDateColumnFilter',
    },
    {
      headerName: 'To',
      width: 140,
      field: 'maxDatePurchase',
      colId: 'maxDatePurchase',
      filter: 'agDateColumnFilter',
    },
    {
      headerName: 'Record #',
      field: 'recordCount',
      colId: 'recordCount',
      width: 140,
    },
    {
      headerName: 'Conflict #',
      field: 'conflicts',
      colId: 'conflicts',
      width: 140,
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      colId: 'dueDate',
      editable: true,
      cellEditorFramework: AgGridDatePickerEditor,
      valueSetter: ({ data, node, newValue }) => {
        if (!node || node?.group) {
          return false
        }
        updateStudy({
          input: {
            dueDate: newValue !== null ? newValue.format('YYYY-MM-DD') : null,
          },
          id: data.id,
        }).then(() => {
          node.updateData({ ...data, dueDate: newValue?.format('YYYY-MM-DD') })
          message.success('Updated')
        })
        return true
      },
    },
    {
      headerName: 'Specialist',
      field: 'user.username',
      colId: 'user.username',
      width: 140,
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditorFramework: CurvoAdminUserSelectAgWrapper,
      valueSetter: ({ data, node, newValue }) => {
        if (!node || node?.group) {
          return false
        }
        if (newValue) {
          updateStudy({
            input: {
              userId: (JSON.parse(newValue.key) as User).id,
            },
            id: data.id,
          }).then(() => {
            node.updateData({ ...data, user: JSON.parse(newValue.key) })
            message.success('Updated')
          })
        }
        return true
      },
    },
    {
      headerName: 'Version',
      field: 'version',
      colId: 'version',
      width: 140,
    },
    {
      headerName: 'Date Uploaded',
      field: 'createdAt',
      colId: 'createdAt',
      valueFormatter: ({ value }) => {
        if (value) {
          const time = new Date(parseInt(value, 10))
          return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`
        }
        return ''
      },
    },
    {
      headerName: 'Status',
      colId: 'status',
      width: 250,
      valueGetter: ({ data }) =>
        `${data.status}.${data.bamfMatchedCount}.${data.recordCount}.${data.linkedTsId}.${data.totalUniqueTsId}`,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [
          'New',
          'AutoMatching',
          'Grooming',
          'Grooming2',
          'Commiting',
          'Failed',
          'CaseReview',
          'CommitingCase',
          'Submitted',
          'Completed',
          'Archived',
        ],
      },
      cellRendererFramework: StudyStatusAgRenderer,
    },
  ]

  const { data: meData } = useContext(MeWithoutPaymentContext)

  const getContextMenuItems = (props: GetContextMenuItemsParams) => {
    const archiveStudyMenu: MenuItemDef = {
      name: 'Archive Study',
      action: () => {
        archiveStudyMutation({ id: props.node.data.id })
      },
    }
    const deleteStudyMenu: MenuItemDef = {
      name: 'Delete Study',
      action: () => {
        Modal.confirm({
          title: `Do you want to archive/delete this study (id=${props.node.data.id})?`,
          content: 'This action cannot be undone',
          onOk: () => {
            const id = props.node.data.id
            deleteStudyMutation({ id })
              .then(res => {
                message.success('Deleted Study ' + id)
                if (gridRef.current) {
                  gridRef.current.api.applyTransaction({
                    remove: [res?.data?.deleteStudy],
                  })
                }
              })
              .catch(e => message.error(e.message))
          },
        })
      },
    }
    const downloadMenus: MenuItemDef[] = []
    if (props.node.data && props.node.data.status) {
      const onClickDownloadMenuItem = onClickDownloadMenuItemBase(props.node.data)
      if (
        [
          StudyStatusEnum.Grooming,
          StudyStatusEnum.Grooming2,
          StudyStatusEnum.Submitted,
          StudyStatusEnum.CaseReview,
        ].indexOf(props.node.data.status) !== -1
      ) {
        downloadMenus.push(
          { name: 'Original File', action: () => onClickDownloadMenuItem('originalFile') },
          {
            name: 'Auto-Groomed File',
            action: () => onClickDownloadMenuItem('groomingResult'),
          },
        )
      }
      if (
        [StudyStatusEnum.CaseReview, StudyStatusEnum.Submitted, StudyStatusEnum.Completed].indexOf(
          props.node.data.status,
        ) !== -1
      ) {
        downloadMenus.push(
          {
            name: 'Commited Parts',
            action: () => onClickDownloadMenuItem('committedParts'),
          },
          {
            name: 'Commited Cases',
            action: () => onClickDownloadMenuItem('committedCases'),
          },
        )
      }
      if ([StudyStatusEnum.Submitted, StudyStatusEnum.Completed].indexOf(props.node.data.status) !== -1) {
        downloadMenus.push({
          name: 'Final Parts',
          action: () => onClickDownloadMenuItem('finalParts'),
        })
        downloadMenus.push({
          name: 'Final Cases',
          action: () => onClickDownloadMenuItem('finalCases'),
        })
      }
    }
    const exportStudyMenu: MenuItemDef = {
      name: 'Export',
      subMenu: downloadMenus,
    }
    const selectedStudy: Study = props.node.data

    const doLinkStudyMenu: MenuItemDef = {
      name: 'Link Ts ID',
      action: () => {
        message.info('Start linking ts id')
        linkStudyMutation({ id: props.node.data.id }).catch(e => message.error(e.message))
      },
    }
    const goToLinkStudyMenu: MenuItemDef = {
      name: 'Go to Link Ts ID',
      action: () => {
        navigate(`/data-cleaning/${selectedStudy.id}/ts-id-links`)
      },
    }
    const retryLinkStudyMenu: MenuItemDef = {
      name: 'Retry Linking Study',
      action: () => {
        message.info('Retry Study linking...')
        retryLinkingStudyMutation({ id: props.node.data.id }).catch(e => message.error(e.message))
      },
    }

    const completeGrooming2Menu: MenuItemDef = {
      name: 'Complete',
      action: () => {
        completeStudyMutation({ id: selectedStudy.id }).then(() => {
          message.success('Completed Study ' + selectedStudy.id)
        })
      },
    }

    const finalLinkStudyMenus = selectedStudy.committedStudyLink
      ? [retryLinkStudyMenu, completeGrooming2Menu]
      : selectedStudy.status === StudyStatusEnum.New || selectedStudy.status === StudyStatusEnum.Grooming
      ? !selectedStudy.totalUniqueTsId || selectedStudy.linkedTsId !== selectedStudy.totalUniqueTsId
        ? [doLinkStudyMenu]
        : [goToLinkStudyMenu, retryLinkStudyMenu]
      : []
    const suppliersGroomMenu: MenuItemDef = {
      name: 'Normalize Suppliers',
      action: () => {
        navigate(`/data-cleaning/${selectedStudy.id}/norm-suppliers`)
      },
    }

    return [archiveStudyMenu, deleteStudyMenu, exportStudyMenu, suppliersGroomMenu, ...finalLinkStudyMenus]
  }

  const createConflictStudy = useCallback(() => {
    setLoadingStates(s => ({ ...s, createConflictStudy: true }))
    createNewConflictStudy(studiesQueryArgs)
      .then(res => {
        if (res?.data?.newStudyFromConflictsItems) {
          message.success('Created new study from conflicts id: ' + res.data.newStudyFromConflictsItems.id)
          const nodeTrx = gridRef.current?.api.applyTransaction({
            addIndex: 0,
            add: [res?.data?.newStudyFromConflictsItems],
          })
          if (nodeTrx?.add) {
            gridRef.current?.api.flashCells({ rowNodes: nodeTrx.add })
          }
        } else if (res?.errors?.length) {
          message.error('Error creating new study from conflicts: ' + res?.errors[0].message)
        }
      })
      .catch(e => message.error('Error creating new study from conflicts: ' + e.message))
      .finally(() => {
        setLoadingStates(s => ({ ...s, createConflictStudy: false }))
      })
  }, [studiesQueryArgs])

  return (
    <Container>
      <SearchWrapper>
        <Input.Search
          style={{ width: 160 }}
          placeholder="customerId"
          enterButton
          onSearch={v => setStudiesQueryArgs({ ...studiesQueryArgs, customerId: v })}
        />
        <Select
          onChange={(v: string) =>
            setStudiesQueryArgs({
              ...studiesQueryArgs,
              first: (v && parseInt(v.toString(), 10)) || 10,
            })
          }
          value={`${studiesQueryArgs.first} records`}
          style={{ width: 120, marginRight: '2em' }}>
          <Select.Option value={100} key="100">
            100 records
          </Select.Option>
          <Select.Option value={200} key="200">
            200 records
          </Select.Option>
          <Select.Option value={500} key="500">
            500 records
          </Select.Option>
        </Select>
        <DatePicker.RangePicker
          value={[studiesQueryArgs.importedFrom, studiesQueryArgs.importedTo]}
          onChange={(_, dateStrings) => {
            const importedFrom = dateStrings[0] ? moment(dateStrings[0]) : defaultDateFilter[0]
            const importedTo = dateStrings[1] ? moment(dateStrings[1]) : defaultDateFilter[1]
            setStudiesQueryArgs({
              ...studiesQueryArgs,
              importedFrom,
              importedTo,
            })
          }}
        />
        <Select
          style={{ width: 120, marginRight: '2em' }}
          value={isOnlyMe ? 1 : 0}
          onChange={(v: number) => {
            setIsOnlyMe(!v)
            if (meData && meData.id) {
              setStudiesQueryArgs({
                ...studiesQueryArgs,
                userId: meData.id,
              })
            }
          }}>
          <Select.Option key="1" value={0}>
            All specialists
          </Select.Option>
          <Select.Option key="2" value={1}>
            Only Me
          </Select.Option>
        </Select>
        <Select
          mode="multiple"
          value={studiesQueryArgs.fileType || undefined}
          maxTagCount={1}
          style={{ width: 200, marginRight: '2em' }}
          onChange={(v: StudyFileTypeEnum[]) => {
            setStudiesQueryArgs(old => ({ ...old, fileType: v }))
          }}>
          <Select.Option key="1" value={StudyFileTypeEnum.Epic}>
            EPIC
          </Select.Option>
          <Select.Option key="2" value={StudyFileTypeEnum.Other}>
            Other
          </Select.Option>
          <Select.Option key="3" value={StudyFileTypeEnum.Contract}>
            Contract
          </Select.Option>
          <Select.Option key="3" value={StudyFileTypeEnum.XRef}>
            XRef
          </Select.Option>
        </Select>
        <Select
          mode="multiple"
          style={{ width: 200 }}
          value={selectedStatus}
          maxTagCount={1}
          onChange={(v: StudyStatusEnum[]) => {
            setSelectedStatus(v || undefined)
          }}
          onBlur={(v: StudyStatusEnum[]) => {
            setStudiesQueryArgs({ ...studiesQueryArgs, statusIn: v || undefined })
          }}>
          <Select.Option key="1" value={StudyStatusEnum.New}>
            New
          </Select.Option>
          <Select.Option key="3" value={StudyStatusEnum.AutoMatching}>
            Auto-Matching
          </Select.Option>
          <Select.Option key="4" value={StudyStatusEnum.Grooming}>
            Grooming
          </Select.Option>
          <Select.Option key="16" value={StudyStatusEnum.Grooming2}>
            Grooming2
          </Select.Option>
          <Select.Option key="5" value={StudyStatusEnum.Committing}>
            Committing
          </Select.Option>
          <Select.Option key="6" value={StudyStatusEnum.CaseReview}>
            CaseReview
          </Select.Option>
          <Select.Option key="7" value={StudyStatusEnum.CommittingCase}>
            CommittingCase
          </Select.Option>
          <Select.Option key="8" value={StudyStatusEnum.Submitted}>
            Submitted
          </Select.Option>
          <Select.Option key="9" value={StudyStatusEnum.Failed}>
            Failed
          </Select.Option>
          <Select.Option key="10" value={StudyStatusEnum.Archived}>
            Archived
          </Select.Option>
          <Select.Option key="11" value={StudyStatusEnum.Completed}>
            Completed
          </Select.Option>
          <Select.Option key="12" value={StudyStatusEnum.QueuedAutoMatching}>
            Queued Auto-Matching
          </Select.Option>
          <Select.Option key="13" value={StudyStatusEnum.QueuedForGrooming2}>
            Queued Grooming
          </Select.Option>
          <Select.Option key="14" value={StudyStatusEnum.QueuedForCommitting}>
            Queued Committing
          </Select.Option>
          <Select.Option key="15" value={StudyStatusEnum.QueuedForPushingToGrooming1}>
            Queued Pushing To Grooming 1
          </Select.Option>
        </Select>

        <Button type="primary" disabled={loadingStates.importRawTransaction} onClick={() => setIsOpenImportPanel(true)}>
          {loadingStates.importRawTransaction ? <Icon spin type="sync" /> : <Icon type="import" />}
          {loadingStates.importRawTransaction ? 'Importing' : 'Import'}
        </Button>
        <Popconfirm
          title={
            <>
              {'A new study will be created includes all the conflicts items from the current studies in the grid'}
              <br />
              {'Do you want to continue?'}
            </>
          }
          onConfirm={createConflictStudy}
          okText="Yes"
          cancelText="No">
          <Button type="primary" disabled={loadingStates.createConflictStudy}>
            {loadingStates.createConflictStudy ? <Icon spin type="sync" /> : <Icon type="block" />}
            {loadingStates.createConflictStudy ? 'Creating' : 'Create Conflict Study'}
          </Button>
        </Popconfirm>
      </SearchWrapper>
      <div className="ag-theme-material" style={{ height: '70vh', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columns}
          modules={[...AllModules]}
          applyColumnDefOrder={false}
          defaultColDef={{ filter: true, sortable: true, resizable: true, autoHeight: true }}
          onDragStopped={(e: DragStoppedEvent) => {
            const columnState = JSON.stringify(
              e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'pinned', 'hide'])),
            )
            localStorage.setItem(STUDY_GRID, columnState)
          }}
          onGridReady={({ api, columnApi }: GridReadyEvent) => {
            const columnStateStr = localStorage.getItem(STUDY_GRID)
            if (columnStateStr) {
              const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
              const currentColumnStates = columnApi.getColumnState()
              const unsavedColumns = currentColumnStates.filter(
                col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
              )

              columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
              columnApi.setColumnVisible('state', false)

              columnApi.moveColumn('index', 0)
            }

            columnApi.getColumn('id')?.setSort('desc')
            if (loading) {
              api.showLoadingOverlay()
            }
            api.onFilterChanged()
          }}
          getRowNodeId={row => row.id}
          getContextMenuItems={getContextMenuItems}
        />
      </div>
      <ImportRawTransactionDrawer
        title={'Import transactions'}
        visible={isOpenImportPanel}
        onCancel={() => setIsOpenImportPanel(false)}
        onSave={input => {
          setLoadingStates({ ...loadingStates, importRawTransaction: true })
          importRawTransactionMutation({ input })
            .then(result => {
              const newStudy = result && result.data && result.data.newStudyFromRawTransactionsFile
              if (newStudy && gridRef.current) {
                gridRef.current.api.applyTransaction({
                  add: [newStudy],
                })
              }
            })
            .finally(() => {
              setLoadingStates({ ...loadingStates, importRawTransaction: false })
            })
          setIsOpenImportPanel(false)
        }}
      />
    </Container>
  )
}

const onClickDownloadMenuItemBase = (data: Study) => async (item: keyof StudyArtifacts) => {
  const response = await genStudyArtifactsUrlMutation({
    id: data.id,
  })
  if (response) {
    if (response.errors) {
      message.error(response.errors[0].message)
    } else if (response.data && response.data.genArtifactsUrl[item]) {
      window.location.href = response.data.genArtifactsUrl[item] || '/'
    }
  }
}

const StudyStatusAgRenderer: React.FC<{ data: Study; api: GridApi }> = props => {
  const { data } = props

  if (data.status === StudyStatusEnum.AutoMatching) {
    return (
      <React.Fragment>
        <span>Auto-Matching </span>
        <Progress
          type="circle"
          width={36}
          percent={Math.round(((data.bamfMatchedCount || 0) * 100.0) / (data.recordCount || 1))}
        />
        <Button
          icon="stop"
          title="cancel"
          onClick={() => cancelStudyMutation({ id: data.id }).catch(e => message.error(e.message))}
        />
      </React.Fragment>
    )
  }

  if (data.status === StudyStatusEnum.AutoLinking) {
    return (
      <React.Fragment>
        <span>Auto-Linking </span>
        <Progress
          type="circle"
          width={36}
          percent={Math.round(((data.linkedTsId || 0) * 100.0) / (data.totalUniqueTsId || 1))}
        />
        <Button
          icon="stop"
          title="cancel"
          onClick={() => cancelStudyMutation({ id: data.id }).catch(e => message.error(e.message))}
        />
      </React.Fragment>
    )
  }

  if (data.status === StudyStatusEnum.Grooming) {
    return (
      <React.Fragment>
        <span>Grooming </span>
        <Button
          icon="rollback"
          title="Retry Matching Study"
          onClick={() => {
            message.info('Retry matching Study...')
            retryStudyMutation({ id: data.id }).catch(e => message.error(e.message))
          }}
        />
      </React.Fragment>
    )
  }

  if (data.status === StudyStatusEnum.Grooming2) {
    return (
      <React.Fragment>
        <span>Grooming 2 </span>
        <Button
          icon="rollback"
          title="Retry Linking Study"
          onClick={() => {
            message.info('Retry linking Study...')
            retryLinkingStudyMutation({ id: props.data.id }).catch(e => message.error(e.message))
          }}
        />
      </React.Fragment>
    )
  }

  if (data.status === StudyStatusEnum.CaseReview) {
    return (
      <React.Fragment>
        <span>Case Review </span>
        <Button
          icon="rollback"
          title="Rollback Study"
          onClick={() => {
            message.info('Rolling back Study...')
            rollbackStudyMutation({ id: data.id }).catch(e => message.error(e.message))
          }}
        />
      </React.Fragment>
    )
  }

  if (data.status === StudyStatusEnum.Committing) {
    return (
      <React.Fragment>
        <span>Committing</span>
        <Progress
          type="circle"
          width={36}
          percent={Math.round(((data.gradedPoCount || 0) * 100.0) / (data.poCount || 1))}
        />
        <Button
          icon="stop"
          title="cancel"
          onClick={() => cancelStudyMutation({ id: data.id }).catch(e => message.error(e.message))}
        />
      </React.Fragment>
    )
  }

  if (data.status === StudyStatusEnum.New) {
    return (
      <React.Fragment>
        <span>NEW </span>
        <Button
          icon="import"
          onClick={() => {
            linkStudyMutation({ id: props.data.id }).catch(e => message.error(e.message))
            // matchStudyMutation({ id: data.id }).catch(err => message.error(err.message))
          }}
        />
      </React.Fragment>
    )
  }
  if (data.status === StudyStatusEnum.Failed) {
    return (
      <React.Fragment>
        <span>Failed </span>

        <Button
          icon="rollback"
          title="recover study"
          onClick={() => {
            recoverStudyMutation({ id: data.id }).catch(e => message.error(e.message))
          }}
        />
      </React.Fragment>
    )
  }
  if (data.status === StudyStatusEnum.Submitted) {
    return (
      <>
        <Button
          icon="rollback"
          title="Rollback study"
          onClick={() => {
            message.info('Rolling back Study...')
            rollbackStudyMutation({ id: data.id }).catch(e => message.error(e.message))
          }}
        />
      </>
    )
  }
  return <span>{data.status}</span>
}

const Container = styled.div`
  margin: 24px;
`

export const StudyIdAgRenderer: React.FC<{ data: any; history: H.History }> = ({ data }) => {
  const navigate = useNavigate()

  const rows: Study[] = [data].flat()
  return (
    <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
      {rows.map(record => (
        <span key={record.id}>
          <Button
            type="link"
            style={{ padding: '2px' }}
            onClick={() => {
              if (record.status === StudyStatusEnum.Grooming || record.status === StudyStatusEnum.AutoMatching) {
                navigate(`/data-cleaning/${record.id}/transaction`)
              } else if (record.status === StudyStatusEnum.Grooming2) {
                navigate(`/data-cleaning/${record.id}/ts-id-links`)
              } else if (record.status === StudyStatusEnum.CaseReview) {
                navigate(`/data-cleaning/${record.id}/case-review`)
              } else if (record.status === StudyStatusEnum.Submitted) {
                navigate(`/data-cleaning/${record.id}/case-review/details`)
              }
            }}>
            {record && record.id}
          </Button>
          {(record.status === StudyStatusEnum.AutoMatching ||
            record.status === StudyStatusEnum.Committing ||
            record.status === StudyStatusEnum.CommittingCase) && <Icon type="sync" spin />}
        </span>
      ))}
    </div>
  )
}
