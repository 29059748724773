import { Subscriber } from 'rxjs'

export function jsonToTable<T>(observer: Subscriber<T>) {
  return new WritableStream({
    write(chunk: T) {
      observer.next(chunk)
    },
    close() {
      observer.complete()
    },
  })
}

export const splitStream = () => {
  const splitOn = '\n,\n'
  let buffer = ''
  return new TransformStream({
    transform(chunk: string, controller) {
      buffer += chunk
      const parts = buffer.split(splitOn)
      parts.slice(0, -1).forEach(part => controller.enqueue(part))
      buffer = parts[parts.length - 1]
    },
    flush(controller) {
      if (buffer) {
        if (buffer === '[]') {
          controller.terminate()
        } else {
          controller.enqueue(buffer)
        }
      }
    },
  })
}

export const parseJSON = () => {
  return new TransformStream({
    transform(chunk: string, controller) {
      if (chunk.startsWith('[')) {
        controller.enqueue(
          chunk.endsWith(']')
            ? chunk.slice(1, -1).trim() !== ''
              ? JSON.parse(chunk.slice(1, -1))
              : []
            : JSON.parse(chunk.slice(1)),
        )
      } else if (chunk.endsWith('}')) {
        controller.enqueue(JSON.parse(chunk))
      } else if (chunk.endsWith(']')) {
        controller.enqueue(JSON.parse(chunk.slice(0, -1)))
      }
    },
  })
}
