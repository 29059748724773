import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
import {
  AllModules,
  ColumnState,
  DragStoppedEvent,
  FillEndEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  RowDataTransaction,
  RowNode,
  ValueFormatterParams,
} from '@ag-grid-enterprise/all-modules'
import {
  bulkUpdatePurchaseOrdersMutation,
  bulkUpdateStudyTransactionsMutation,
  Client,
  ConstructValidationType,
  finishStudyMutation,
  getPurchaseOrder,
  Gic,
  GicTypeOne,
  GicTypeTwo,
  Material,
  PurchaseOrder,
  PurchaseOrderBulkUpdateInput,
  PurchaseOrdersMergeInput,
  purchaseOrdersMergeMutation,
  PurchaseOrderTransactionUpdateInput,
  reGradePurchaseOrderMutation,
  Segment,
  StudyTransactionBulkUpdateInput,
  StudyTransactionType,
  updatePurchaseOrderTransactionMutation,
  useStudyTransactionsData,
} from '@curvo/apollo'
import { Button, Icon, Input, message, Popover, Progress, Tag, Tooltip } from 'antd'
import Axios from 'axios'
import { isNil, omit, pick, range } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Cognito from '../../../config/Cognito'
import { SearchWrapper } from '../Update/common'
import { SegmentSelectAgWrapper, SegmentValueSelectAgWrapper } from '../Update/components/Select/SegmentSelect'
import { currencyFormatter } from './common'
import { ConstructValidationSelectAgWrapper } from './components/ConstructValidationSelect'
import { GICAgWrapper } from './components/GICAgWrapper'
import { AllowedGicTypeOneAgWrapper } from './components/GicTypeOneAgWrapper'
import { AllowedGicTypeTwoAgWrapper } from './components/GicTypeTwoAgWrapper'
import { GradingCache } from './components/GradingCache'
import { GradingConstructSelectAgWrapper } from './components/GradingConstructsSelect'
import { MaterialSelectAgWrapper } from './components/MaterialSelectAgWrapper'
import { PartSearchSelectAgWrapper } from './components/PartSearchAgWrapper'
import { PurchaseOrderPartCreateDrawer } from './components/PurchaseOrderPartCreateDrawer'

const PURCHASE_ORDER_GRADE = 'PURCHASE_ORDER_GRADE'
const PURCHASE_ORDER_TRANSACTION_GRADE = 'PURCHASE_ORDER_TRANSACTION_GRADE '

type PurchaseOrderGradeProps = { studyId: number }
const ROWS_PER_LOAD = 10000

export const PurchaseOrderGrade: React.FC<PurchaseOrderGradeProps> = ({ studyId }) => {
  const gridRef = useRef<AgGridReact & { api: GridApi }>(null)
  const [selectedPurchase, setSelectedPurchase] = useState<PurchaseOrder>()
  const [selectedPurchases, setSelectedPurchases] = useState<PurchaseOrder[]>([])
  const [showMergePopup, setShowMergePopup] = useState(false)

  const updateRowData = (updateTransaction: RowDataTransaction) => {
    const api = gridRef.current && gridRef.current.api
    if (api) {
      api.applyTransaction(updateTransaction)
      GradingCache.updateStudy(updateTransaction, studyId)
    }
  }

  const handleUpdatePurchaseOrder = (input: PurchaseOrderBulkUpdateInput) => {
    const api = gridRef.current && gridRef.current.api
    api && api.showLoadingOverlay()
    bulkUpdatePurchaseOrdersMutation({
      input,
    })
      .then(result => {
        const updated = result && result.data && result.data.bulkUpdatePurchaseOrders
        if (updated && api) {
          updateRowData({ update: updated })
        }
        message.success('Updated case')
      })
      .catch(e => message.error(e.message))
      .finally(() => {
        api && api.hideOverlay()
      })
    return true
  }

  const handleOnFillEnd = async ({ api, finalRange, initialRange }: FillEndEvent) => {
    if (finalRange.columns.length > 1) {
      return
    }
    const selectedColumn = finalRange.columns[0]
    if (selectedColumn.getColDef().headerName === 'Surgeon') {
      const value =
        initialRange.endRow && (api.getDisplayedRowAtIndex(initialRange.endRow.rowIndex)?.data as PurchaseOrder).surgeon
      if (value === undefined) {
        return
      }
      const start = finalRange.startRow!.rowIndex
      const end = finalRange.endRow!.rowIndex
      const selectedIds = range(start, end + 1).map(
        index => (api.getDisplayedRowAtIndex(index)?.data as PurchaseOrder).id,
      )
      handleUpdatePurchaseOrder({
        ids: selectedIds,
        surgeon: value,
      })
    }
    if (selectedColumn.getColDef().headerName === 'Validation Errors') {
      const value =
        initialRange.endRow &&
        (api.getDisplayedRowAtIndex(initialRange.endRow.rowIndex)?.data as PurchaseOrder).validationErrors
      const start = finalRange.startRow!.rowIndex
      const end = finalRange.endRow!.rowIndex
      const selectedIds = range(start, end + 1).map(
        index => (api.getDisplayedRowAtIndex(index)?.data as PurchaseOrder).id,
      )
      handleUpdatePurchaseOrder({
        ids: selectedIds,
        validationErrors: value,
      })
    }
  }

  const handleMergePurchaseOrder = (input: PurchaseOrdersMergeInput) => {
    const api = gridRef.current && gridRef.current.api
    api && api.showLoadingOverlay()
    purchaseOrdersMergeMutation({ input })
      .then(result => {
        const newPO = result && result.data && result.data.mergePurchaseOrders
        if (api) {
          updateRowData({
            remove: input.ids.map(id => ({ id })),
            add: newPO ? [newPO] : undefined,
          })
          newPO && setSelectedPurchase(newPO)
        }
      })
      .catch(e => message.error(e.message))
      .finally(() => {
        api && api.hideOverlay()
      })
  }

  const PurchaseOrderCaseAgRenderer: React.FC<{ data: PurchaseOrder; api: GridApi }> = useCallback(
    props => {
      const { data } = props

      return (
        <Button
          type="link"
          onClick={() => {
            setSelectedPurchase(data)
          }}>
          {data.number}
        </Button>
      )
    },
    [setSelectedPurchase],
  )

  const formatProcedureDate = (params: ValueFormatterParams) => {
    const num = Number(params.value)
    return moment(isNaN(num) ? params.value : num).format('MM/DD/YYYY')
  }

  const purchaseOrdersColumns: AgGridColumnProps[] = [
    {
      headerName: 'Case ID',
      field: 'number',
      colId: 'number',
      cellRendererFramework: PurchaseOrderCaseAgRenderer,
    },
    {
      headerName: 'Segment',
      field: 'segment',
      colId: 'segment',
      editable: true,
      cellEditorFramework: SegmentSelectAgWrapper,

      valueSetter: ({ data, newValue }) => {
        const purchaseOrder: PurchaseOrder = data
        return handleUpdatePurchaseOrder({ ids: [purchaseOrder.id], segment: newValue.label })
      },
    },
    {
      headerName: 'Construct',
      field: 'construct',
      colId: 'construct',
      editable: true,
      cellEditorFramework: GradingConstructSelectAgWrapper,
      valueGetter: ({ data }) => {
        const po: PurchaseOrder = data
        return po.construct ? `${po.construct.conId.padEnd(4, ' ')} | ${po.construct.conName}` : ''
      },

      valueSetter: ({ data, newValue }) => {
        const purchaseOrder: PurchaseOrder = data
        if (newValue !== undefined) {
          return handleUpdatePurchaseOrder({ ids: [purchaseOrder.id], constructId: newValue.key })
        }
        return false
      },
    },
    {
      headerName: 'Total Cost',
      field: 'totalValue',
      colId: 'totalValue',
      type: 'numericColumn',
      valueFormatter: currencyFormatter,
    },
    {
      headerName: 'Unapproved Parts',
      field: 'unapprovedParts',
      type: 'numericColumn',
      colId: 'unapprovedParts',
    },
    {
      headerName: 'Validation Errors',
      field: 'validationErrors',
      colId: 'validationErrors',
      cellEditorFramework: ConstructValidationSelectAgWrapper,
      editable: true,
      cellRendererFramework: PurchaseOrderValidationErrorAgRenderer,
      valueGetter: ({ data }) => {
        let result = ''

        if (data && data.validationErrors) {
          result = data.validationErrors.map(item => `${item.conId} | ${item.message}`).join(', ')
        }

        return result
      },

      valueSetter: ({ data, newValue }) => {
        if (newValue !== undefined && !newValue.fillValue) {
          const purchaseOrder: PurchaseOrder = data
          return handleUpdatePurchaseOrder({
            ids: [purchaseOrder.id],
            validationErrors: (newValue || []).map(v => ({
              conId: v.conId,
              message: v.message,
            })),
          })
        }
        return false
      },
    },
    {
      headerName: 'Surgeon',
      field: 'surgeon',
      colId: 'surgeon',
      editable: true,
      enableRowGroup: true,

      valueSetter: ({ data, newValue }) => {
        const purchaseOrder: PurchaseOrder = data
        if (newValue === null || typeof newValue === 'string') {
          return handleUpdatePurchaseOrder({ ids: [purchaseOrder.id], surgeon: newValue })
        }
        return false
      },
    },
    {
      headerName: 'Procedure Date',
      field: 'procdate',
      colId: 'procdate',
      valueFormatter: formatProcedureDate,
    },
  ]

  const { error, progress, loading, cachedData } = useLoadPurchaseOrders(studyId, gridRef)
  if (error) {
    message.error(error.message)
  }

  const extras = [
    <Popover
      key="stats"
      content={
        <MergeButtonPopOverContent
          selectedPurchases={selectedPurchases}
          setShowMergePopup={setShowMergePopup}
          handleMergePurchaseOrder={handleMergePurchaseOrder}
        />
      }
      trigger="click"
      onVisibleChange={state => setShowMergePopup(state)}
      visible={showMergePopup}
      title={`Merge ${selectedPurchases.length} Purchase Orders`}>
      <Button key="2" type="default" disabled={selectedPurchases.length < 2}>
        Merge
      </Button>
    </Popover>,
    <Button
      key="1"
      type="primary"
      onClick={() => {
        finishStudyMutation({ id: studyId })
        window.location.href = `${window.location.href}/details`
      }}>
      Submit
    </Button>,
  ]

  return (
    <>
      <SearchWrapper>{extras}</SearchWrapper>
      <div>
        {loading && <Progress percent={progress} />}
        <div className="ag-theme-balham" style={{ height: '35vh', width: 'fill-parent', marginBottom: '16px' }}>
          <AgGridReact
            applyColumnDefOrder={false}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
            }}
            onDragStopped={(e: DragStoppedEvent) => {
              const columnState = JSON.stringify(
                e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'pinned', 'hide'])),
              )
              localStorage.setItem(PURCHASE_ORDER_GRADE, columnState)
            }}
            modules={[...AllModules]}
            columnDefs={purchaseOrdersColumns}
            onFillEnd={handleOnFillEnd}
            ref={gridRef}
            enableRangeSelection={true}
            enableFillHandle={true}
            rowSelection="multiple"
            fillOperation={() => ({ fillValue: true })}
            onSelectionChanged={e => {
              setSelectedPurchases(e.api.getSelectedRows())
            }}
            onGridReady={({ api, columnApi }) => {
              const columnStateStr = localStorage.getItem(PURCHASE_ORDER_GRADE)
              if (columnStateStr) {
                const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
                const currentColumnStates = columnApi.getColumnState()
                const unsavedColumns = currentColumnStates.filter(
                  col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
                )

                columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
                columnApi.setColumnVisible('state', false)
              }
              api.onFilterChanged()
              if (cachedData) {
                api.setRowData([...cachedData])
              }
            }}
            getRowNodeId={row => row.id}
          />
        </div>
        {selectedPurchase && (
          <PurchaseOrderTransactionsGrid
            purchaseOrder={selectedPurchase}
            onUpdatedPurchaseOrderTransaction={({ update, add }) => {
              if (gridRef.current) {
                gridRef.current.api.deselectAll()
                updateRowData({
                  add: add && [add],
                  update: update && [update],
                })
                setSelectedPurchase(selectedPurchase)
              }
            }}
            availablePurchaseOrders={[...(cachedData || [])].map(({ number }) => number)}
          />
        )}
      </div>
    </>
  )
}

type PurchaseOrderTransactionsGridProps = {
  purchaseOrder: PurchaseOrder
  onUpdatedPurchaseOrderTransaction: (update: { add?: PurchaseOrder; update?: PurchaseOrder }) => void
  availablePurchaseOrders: string[]
}

const PurchaseOrderTransactionsGrid: React.FC<PurchaseOrderTransactionsGridProps> = ({
  purchaseOrder,
  onUpdatedPurchaseOrderTransaction,
  availablePurchaseOrders,
}) => {
  const gridRef = useRef<AgGridReact & { api: GridApi }>(null)

  const [showCreatePanel, setShowCreatePanel] = useState(false)
  const [selectedTransactions, setSelectedTransactions] = useState<StudyTransactionType[]>()
  const [selectedNode, setSelectedNode] = useState<RowNode>()
  const [showSplitPopup, setShowSplitPopup] = useState(false)
  const [drawerEditMode, setDrawerEditMode] = useState(false)
  const [edited, setEdited] = useState(false)

  const handleUpdatePurchaseOrderTransaction = useCallback(
    (input: StudyTransactionBulkUpdateInput, node: RowNode) => {
      const api = gridRef.current && gridRef.current.api
      api && api.showLoadingOverlay()
      setEdited(true)
      bulkUpdateStudyTransactionsMutation({
        input,
      })
        .then(result => {
          const updated = result && result.data && result.data.updateBulkStudyTransactions
          if (updated && updated.length) {
            node.setData(updated[0])
            onUpdatedPurchaseOrderTransaction({
              update: (updated[0] && updated[0].purchaseOrder) || undefined,
            })
          }
          message.success('Updated Transaction')
        })
        .catch(e => {
          message.error(e.message)
        })
        .finally(() => {
          api && api.hideOverlay()
        })
      return true
    },
    [onUpdatedPurchaseOrderTransaction],
  )

  const handleAddRemovePurchaseOrderTransaction = async (input: PurchaseOrderTransactionUpdateInput) => {
    const api = gridRef.current && gridRef.current.api
    if (api) {
      api.showLoadingOverlay()
    }
    setEdited(true)
    const result = await updatePurchaseOrderTransactionMutation({
      input,
    })
      .catch(e => {
        message.error(e.message)
      })
      .finally(() => {
        api && api.hideOverlay()
      })

    const updated = result && result.data && result.data.updatePurchaseOrderTransaction
    if (updated) {
      if (api) {
        api.applyTransaction({
          [input.newTransaction ? 'add' : 'remove']: [updated],
        })
      }
      onUpdatedPurchaseOrderTransaction({
        update: (updated && updated.purchaseOrder) || undefined,
      })
    }
    message.success(input.newTransaction ? 'Added' : 'Deleted')
  }

  const handleSplitPurchaseOrder = async (input: StudyTransactionBulkUpdateInput) => {
    const api = gridRef.current && gridRef.current.api
    api && api.showLoadingOverlay()
    setEdited(true)
    const result = await bulkUpdateStudyTransactionsMutation({ input })
      .catch(e => {
        message.error(e.message)
      })
      .finally(() => {
        api && api.hideOverlay()
      })
    const updated = result && result.data && result.data.updateBulkStudyTransactions
    if (api) {
      api.applyTransaction({
        remove: updated ? updated.map(transaction => ({ id: transaction.id })) : undefined,
      })
    }
    const { data } = await getPurchaseOrder(purchaseOrder.studyId, purchaseOrder.number)
    const updatedPO = data && data.purchaseOrder
    onUpdatedPurchaseOrderTransaction({
      update: updatedPO,
      add: (updated && updated.length && updated[0].purchaseOrder) || undefined,
    })
  }

  const getContextMenuItems = ({ node }: GetContextMenuItemsParams): MenuItemDef[] => {
    return [
      {
        name: 'Delete this part',
        action: () =>
          handleAddRemovePurchaseOrderTransaction({
            id: purchaseOrder.id,
            removeTransactionId: node.data.id,
          }),
      },
      {
        name: 'Edit this part',
        action: () => {
          if (!node.group) {
            setDrawerEditMode(true)
            setShowCreatePanel(true)
            setSelectedNode(node)
          }
        },
      },
    ]
  }
  const wasteCellRenderer = useCallback(
    ({ value }) => (value !== undefined ? <Icon type={value ? 'check' : 'close'} /> : null),
    [],
  )
  const columnDefs: AgGridColumnProps[] = useMemo(
    () => [
      {
        headerName: 'Manufacturer',
        colId: 'manufacturer',
        valueGetter: ({ data }) => {
          const transaction: StudyTransactionType = data
          return (
            transaction &&
            transaction.matchManufacturerId &&
            `${transaction.matchManufacturerId} | ${transaction.bamfManufacturer}`
          )
        },
      },
      {
        headerName: 'Part Number',
        field: 'bamfPartNumber',
        colId: 'bamfPartNumber',
        editable: true,
        cellEditorFramework: PartSearchSelectAgWrapper,
        valueSetter: ({ data, node, newValue }) => {
          if (newValue && node) {
            const transaction: StudyTransactionType = data
            return handleUpdatePurchaseOrderTransaction(
              { ids: [transaction.id], studyId: purchaseOrder.studyId, matchPartId: newValue.key },
              node,
            )
          }
          return false
        },
      },
      {
        headerName: 'Description',
        field: 'bamfPartDescription',
        colId: 'bamfPartDescription',
      },
      {
        headerName: 'Gic',
        field: 'gicName',
        colId: 'gicName',
        editable: true,
        cellEditorFramework: GICAgWrapper,
        valueGetter: ({ data }) => data && data.gicId && `${data.gicId} | ${data.gicName}`,
        valueSetter: ({ data, node, newValue }) => {
          if (node?.group) {
            message.error('You can only edit gic for one transaction at a time')
            return false
          }
          if (newValue && node) {
            return handleUpdatePurchaseOrderTransaction(
              {
                studyId: purchaseOrder.studyId,
                ids: [data.id],
                gic: omit<Gic & { __typename?: string }, 'imageUrl' | '__typename'>(newValue, [
                  'imageUrl',
                  '__typename',
                ]),
              },
              node,
            )
          }
          return false
        },
      },
      {
        headerName: 'Price',
        field: 'unitprice',
        colId: 'unitprice',
        width: 100,
        valueFormatter: currencyFormatter,
        type: 'numericColumn',
      },
      {
        headerName: 'Qty',
        width: 80,
        field: 'qtypurchase',
        colId: 'qtypurchase',
        type: 'numericColumn',
      },
      {
        headerName: 'Ext Price',
        width: 100,
        field: 'extprice',
        colId: 'extprice',
        type: 'numericColumn',
        editable: true,
        valueFormatter: currencyFormatter,
        valueSetter: ({ data, node, newValue }) => {
          if (newValue && node) {
            const transaction: StudyTransactionType = data
            return handleUpdatePurchaseOrderTransaction(
              {
                ids: [transaction.id],
                studyId: purchaseOrder.studyId,
                extprice: parseFloat(newValue),
              },
              node,
            )
          }
          return false
        },
      },
      {
        headerName: 'Type One',
        field: 'typeOneName',
        colId: 'typeOneName',
        editable: true,
        cellEditorFramework: AllowedGicTypeOneAgWrapper,
        valueSetter: ({ data, node, newValue }) => {
          if (node?.group) {
            message.error('You can only edit gic type one for one transaction at a time')
            return false
          }
          if (newValue && node) {
            return handleUpdatePurchaseOrderTransaction(
              {
                studyId: purchaseOrder.studyId,
                ids: [data.id],
                typeOne: omit<GicTypeOne & { __typename?: string }, '__typename'>(newValue, ['__typename']),
              },
              node,
            )
          }
          return false
        },
      },
      {
        headerName: 'Type Two',
        field: 'typeTwoName',
        colId: 'typeTwoName',
        editable: true,
        cellEditorFramework: AllowedGicTypeTwoAgWrapper,
        valueSetter: ({ data, node, newValue }) => {
          if (node?.group) {
            message.error('You can only edit gic type two for one transaction at a time')
            return false
          }
          if (newValue && node) {
            return handleUpdatePurchaseOrderTransaction(
              {
                studyId: purchaseOrder.studyId,
                ids: [data.id],
                typeTwo: omit<GicTypeTwo & { __typename?: string }, '__typename'>(newValue, ['__typename']),
              },
              node,
            )
          }
          return false
        },
      },
      {
        headerName: 'Segment',
        field: 'segmentName',
        colId: 'segmentName',
        editable: true,
        cellEditorFramework: SegmentValueSelectAgWrapper,
        valueSetter: ({ data, node, newValue }) => {
          if (node?.group) {
            message.error('You can only edit segment for one transaction at a time')
            return false
          }
          if (newValue && node) {
            return handleUpdatePurchaseOrderTransaction(
              {
                studyId: purchaseOrder.studyId,
                ids: [data.id],
                segment: omit<Segment & { __typename?: string }, '__typename'>(newValue, ['__typename']),
              },
              node,
            )
          }
          return false
        },
      },
      {
        headerName: 'Material',
        field: 'materialName',
        colId: 'materialName',
        editable: true,
        cellEditorFramework: MaterialSelectAgWrapper,
        valueSetter: ({ data, node, newValue }) => {
          if (node?.group) {
            message.error('You can only edit material for one transaction at a time')
            return false
          }
          if (newValue && node) {
            return handleUpdatePurchaseOrderTransaction(
              {
                studyId: purchaseOrder.studyId,
                ids: [data.id],
                material: omit<Material & { __typename?: string }, '__typename'>(newValue, ['__typename']),
              },
              node,
            )
          }
          return false
        },
      },
      {
        headerName: 'IN Vendor',
        field: 'vendor',
        colId: 'vendor',
      },
      {
        headerName: 'IN Manufacturer',
        field: 'manufacturer',
        colId: 'manufacturer',
      },
      {
        headerName: 'IN venitem',
        field: 'venitem',
        colId: 'venitem',
      },
      {
        headerName: 'IN mfgitem',
        field: 'mfgitem',
        colId: 'mfgitem',
      },
      {
        headerName: 'IN Description',
        field: 'description',
        colId: 'description',
      },
      {
        headerName: 'Is Approved',
        field: 'isApproved',
        colId: 'isApproved',
      },
      {
        headerName: 'WASTE',
        field: 'waste',
        colId: 'waste',
        width: 80,
        editable: true,

        cellRendererFramework: wasteCellRenderer,
        valueSetter: ({ data, node, newValue: rawNewValue }) => {
          if ((rawNewValue === 'true' || rawNewValue === 'false') && node) {
            const newValue = rawNewValue === 'true'
            const updateQueryParams = node.group
              ? {
                  ids: (node.childrenAfterFilter || [])
                    .map(row => row.id)
                    .filter(id => !!id)
                    .map(id => id!),
                  isSupplierVen: node.field === 'vendor',
                }
              : {
                  ids: [data.id],
                }
            if (updateQueryParams.ids && updateQueryParams.ids.length > 0) {
              handleUpdatePurchaseOrderTransaction(
                { ...updateQueryParams, studyId: purchaseOrder.studyId, waste: newValue },
                node,
              )
              return true
            }
          }

          return false
        },
      },
    ],
    [wasteCellRenderer, handleUpdatePurchaseOrderTransaction, purchaseOrder.studyId],
  )

  const studyTransactionsResult = useStudyTransactionsData({
    variables: {
      studyId: purchaseOrder.studyId,
      ponum: purchaseOrder.number,
      first: 1000,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (studyTransactionsResult.error) {
      message.error(studyTransactionsResult.error.message)
    } else if (studyTransactionsResult.loading) {
      gridRef.current && gridRef.current.api.showLoadingOverlay()
    } else {
      const data = studyTransactionsResult && studyTransactionsResult.data
      const transactions = ((data && data.studyTransactions.edges) || []).map(edge => edge.node)
      gridRef.current && gridRef.current.api.setRowData(transactions)
      gridRef.current && gridRef.current.api.hideOverlay()
    }
  }, [studyTransactionsResult])

  return (
    <React.Fragment>
      <div style={{ marginBottom: '12px', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button
            icon="plus"
            style={{ marginRight: '12px' }}
            onClick={() => {
              setShowCreatePanel(true)
            }}>
            New Part
          </Button>
          <Popover
            trigger="click"
            onVisibleChange={state => setShowSplitPopup(state)}
            visible={showSplitPopup}
            content={
              <SplitPurchaseOrderPopoverContent
                purchaseOrder={purchaseOrder}
                availablePurchaseOrders={availablePurchaseOrders}
                handleSplitPurchaseOrder={handleSplitPurchaseOrder}
                selectedTransactions={selectedTransactions}
                setShowSplitPopup={setShowSplitPopup}
              />
            }>
            <Button disabled={!selectedTransactions || selectedTransactions.length === 0}>Split</Button>
          </Popover>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              reGradePurchaseOrderMutation({
                studyId: purchaseOrder.studyId,
                ponum: purchaseOrder.number,
              }).then(() => {
                message.success('Updated')
                setEdited(false)
              })
            }}
            disabled={!edited}>
            Recalculate
          </Button>
        </div>
      </div>
      <div className="ag-theme-balham" style={{ height: '35vh', width: 'fill-parent' }}>
        <AgGridReact
          ref={gridRef}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          rowSelection="multiple"
          modules={[...AllModules]}
          columnDefs={columnDefs}
          onDragStopped={(e: DragStoppedEvent) => {
            const columnState = JSON.stringify(
              e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'pinned', 'hide'])),
            )
            localStorage.setItem(PURCHASE_ORDER_TRANSACTION_GRADE, columnState)
          }}
          onGridReady={({ api, columnApi }: GridReadyEvent) => {
            const columnStateStr = localStorage.getItem(PURCHASE_ORDER_TRANSACTION_GRADE)
            if (columnStateStr) {
              const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
              const currentColumnStates = columnApi.getColumnState()
              const unsavedColumns = currentColumnStates.filter(
                col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
              )

              columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
              columnApi.setColumnVisible('state', false)

              columnApi.moveColumn('index', 0)
            }
            api.onFilterChanged()

            columnApi.getColumn('gicName')?.setSort('asc')
          }}
          getRowNodeId={data => data.id}
          getContextMenuItems={getContextMenuItems}
          onSelectionChanged={e => {
            setSelectedTransactions(e.api.getSelectedRows())
          }}
        />
      </div>
      <PurchaseOrderPartCreateDrawer
        visible={showCreatePanel}
        isEditMode={drawerEditMode}
        selectedTransaction={drawerEditMode && selectedNode ? selectedNode.data : undefined}
        onSave={input => {
          if (drawerEditMode && selectedNode) {
            handleUpdatePurchaseOrderTransaction(
              {
                studyId: purchaseOrder.studyId,
                ids: [selectedNode.data.id],
                ...input,
              },
              selectedNode,
            )
          } else {
            handleAddRemovePurchaseOrderTransaction({
              id: purchaseOrder.id,
              newTransaction: input,
            })
          }
          setShowCreatePanel(false)
        }}
        onCancel={() => {
          setShowCreatePanel(false)
          setDrawerEditMode(false)
          setSelectedTransactions([])
          setSelectedNode(undefined)
        }}
      />
    </React.Fragment>
  )
}

const PurchaseOrderValidationErrorAgRenderer: React.FC<{
  data: PurchaseOrder
  api: GridApi
}> = ({ data }) => (
  <div>
    {(Array.isArray(data.validationErrors) ? data.validationErrors : []).map((valErr: ConstructValidationType) => (
      <Tooltip title={valErr.message} key={valErr.conId}>
        <Tag>{valErr.conId}</Tag>
      </Tooltip>
    ))}
  </div>
)

type PurchaseOrdersResult = {
  count: number
  data: PurchaseOrder[]
}

type LoadPurchaseOrdersResult = {
  progress?: number
  error?: Error
  loading: boolean
  total?: number
  cachedData?: IterableIterator<PurchaseOrder>
}

export function useLoadPurchaseOrders(
  studyId: number,
  gridRef: React.RefObject<AgGridReact & { api: GridApi }>,
): LoadPurchaseOrdersResult {
  const cachedData = GradingCache.purchaseOrders.get(studyId)
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>(cachedData ? [...cachedData.values()] : [])

  const [total, setTotal] = useState(cachedData ? cachedData.size : undefined)
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getToken = async () => {
      const session = await Cognito.getSession()
      return session.getAccessToken().getJwtToken()
    }
    const loadPurchaseOrders = async () => {
      const baseUrl = Client.getUrl()
      setLoading(true)
      try {
        const token = await getToken()
        const result = await Axios.get<PurchaseOrdersResult>(
          `${window.location.protocol}//${baseUrl}/study/${studyId}/purchase-orders`,
          {
            params: {
              first: ROWS_PER_LOAD,
              skip: purchaseOrders.length,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        setPurchaseOrders(trxes => [...trxes, ...result.data.data])
        GradingCache.addToStudy(result.data.data, studyId)

        gridRef.current &&
          gridRef.current.api.applyTransaction({
            add: result.data.data,
          })
        if (result.data.count > (total || 0) || isNil(total)) {
          setTotal(result.data.count)
        }
        setLoading(false)
      } catch (e) {
        setError(e)
      }
    }

    if (isNil(total) || purchaseOrders.length < total!) {
      if (!loading) {
        loadPurchaseOrders()
      }
    } else if (gridRef.current && gridRef.current.api.getDisplayedRowCount() === 0) {
      gridRef.current.api.setRowData(purchaseOrders)
    }
  }, [studyId, total, loading, purchaseOrders, gridRef])

  return {
    progress: Math.round(purchaseOrders && total ? ((purchaseOrders.length * 1.0) / total) * 100 : 0),
    error,
    loading: !(purchaseOrders.length >= (total || 0) && !isNil(total)),
    total,
    cachedData: cachedData && cachedData.values(),
  }
}

const MergeButtonPopOverContent: React.FC<{
  selectedPurchases: PurchaseOrder[]
  setShowMergePopup: (s: boolean) => void
  handleMergePurchaseOrder: (input: PurchaseOrdersMergeInput) => void
}> = ({ selectedPurchases, setShowMergePopup, handleMergePurchaseOrder }) => {
  const [name, setName] = useState(selectedPurchases[0].number)
  return (
    <div>
      <Input value={name} placeholder="Enter new Purchase Order name" onChange={v => setName(v.target.value)} />
      <Button
        block
        onClick={() => {
          handleMergePurchaseOrder({ ids: selectedPurchases.map(po => po.id), ponum: name })
          setShowMergePopup(false)
        }}>
        Merge
      </Button>
    </div>
  )
}

const SplitPurchaseOrderPopoverContent: React.FC<{
  purchaseOrder: PurchaseOrder
  availablePurchaseOrders: string[]
  handleSplitPurchaseOrder: (input: StudyTransactionBulkUpdateInput) => Promise<void>
  selectedTransactions?: StudyTransactionType[]
  setShowSplitPopup: (b: boolean) => void
}> = ({
  purchaseOrder,
  availablePurchaseOrders,
  handleSplitPurchaseOrder,
  selectedTransactions,
  setShowSplitPopup,
}) => {
  const [name, setName] = useState(purchaseOrder.number)

  return (
    <div>
      <Input value={name} placeholder="Enter new purchase order's name" onChange={v => setName(v.target.value)} />
      <Button
        type="primary"
        block
        disabled={!name || name === purchaseOrder.number || availablePurchaseOrders.includes(name)}
        onClick={() => {
          handleSplitPurchaseOrder({
            studyId: purchaseOrder.studyId,
            ids: selectedTransactions!.map(transaction => transaction.id),
            ponum: name,
          })
          setShowSplitPopup(false)
        }}>
        Split
      </Button>
    </div>
  )
}
